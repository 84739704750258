<template>
  <div>
    <h2>
      <strong>{{ $t("resource") }}:</strong> {{ resourceId }}
    </h2>
    <v-treeview
      dense
      activatable
      hoverable
      rounded
      :items="items"
      :active="activeItems"
      @update:active="onActive"
      open-on-click
      :open="openedItems"
      @update:open="onOpen"
      item-key="id"
      item-text="name"
      open-all
    >
    </v-treeview>
  </div>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import { toTreeData } from "@/managers/collectionEntities";

export default {
  name: "Entities",
  components: {},
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      items: [],
      openedItems: [],
      activeItems: [],
      endpointsList: null
    };
  },
  computed: {
    resourceId: function() {
      return this.$store.state.selected_resource;
    }
  },
  created: async function() {
    const url = this.resourceId + "/_abac/attr-collection";

    this.endpointsList = await callAPI({
      url,
      method: "GET"
    });
    this.items = [toTreeData(this.endpointsList.data)];

    this.openFirstLevel();
  },
  methods: {
    openFirstLevel() {
      if (this.items == null || !Array.isArray(this.items)) return;
      if (this.items.length != 1) return;
      const rootItem = this.items[0];
      const firstLevelIds = rootItem.children.map(p => p.id);
      this.openedItems = [];
      this.openedItems.push(rootItem.id);
      this.openedItems.push(...firstLevelIds);
    },
    onOpen: function(event) {
      this.openedItems = event;
      // get click actions for the root element too
      this.$emit("input", "_root");
    },
    onActive: function(event) {
      this.activeItems = event;
      if (this.activeItems.length != 1) return;
      this.onClick(this.activeItems[0]);
    },
    onClick(nodeId) {
      if (this.isNodeLeaf(this.findNodeById(nodeId))) {
        this.$emit("input", nodeId);
      }
    },
    findNodeById(nodeId) {
      return this.findNodeByIdReq(nodeId, this.items[0]);
    },
    findNodeByIdReq(nodeId, parentNode) {
      if (parentNode.id == nodeId) return parentNode;
      for (const childNode of parentNode.children) {
        const node = this.findNodeByIdReq(nodeId, childNode);
        if (node != null) return node;
      }
    },
    isNodeLeaf(node) {
      if (!node.children) return true;
      if (node.children.length == 0) return true;
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.endpoint-leaf {
  cursor: pointer;
}
</style>
