<template>
  <v-container class="container" fluid>
    <p class="title">
      {{ $t("attributes") }}
      <span class="body-2">( {{ entityName }} )</span>
    </p>
    <v-data-table
      :headers="headers"
      :items="attributes"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      sort-by="name"
      item-key="name"
    >
      <template v-slot:top>
        <v-card class="elevation-0 pa-4">
          <v-btn
            small
            color="primary"
            v-on:click="add_click()"
            v-if="status != statusValues.add && status != statusValues.edit"
            >{{ $t("add_button") }}</v-btn
          >
          <GsGeneralForm
            :config="formConfig"
            :cancel="cancel_click"
            :save="save_click"
            v-if="status == statusValues.add || status == statusValues.edit"
          />
        </v-card>
      </template>
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <GsIdentityAttributeDetails v-model="attribute" />
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              small
              color="error"
              v-on:click="delete_click(item)"
              v-on="on"
              >{{ $t("delete_button") }}</v-btn
            >
          </template>
          <span>{{ $t("collection_delete_tooltip") }}</span>
        </v-tooltip>
        <v-btn small color="primary" v-on:click="edit_click(item)">{{
          $t("edit_button")
        }}</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsGeneralForm from "@/components/GsGeneralForm";

export default {
  name: "CollectionAttributes",
  components: {
    GsGeneralForm
  },
  props: {
    entityName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      attributes: [],
      expanded: [],
      singleExpand: true,
      status: 0,
      statusValues: {
        browse: 0,
        add: 1,
        edit: 2
      },
      attribute: null,
      formConfig: {}
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("name"), align: "left", sortable: true, value: "name" },
        { text: this.$t("actions"), sortable: false, value: "actions" }
      ];
    }
  },
  watch: {
    async entityName() {
      await this.getAttributes();
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getAttributes();
    },
    async getAttributes() {
      if (!this.entityName) {
        this.attributes = [];
        return;
      }
      const result = await callAPI({
        url:
          this.$store.state.selected_resource +
          "/_abac/attr-collection/" +
          this.entityName,
        method: "GET"
      });
      this.attributes = result.data.map(p => ({ id: p, name: p }));
    },
    async add_click() {
      let attribute = {
        name: "",
        value: ""
      };
      this.formConfig = {
        url: this.$store.state.selected_resource,
        path: "_abac/attr-collection/{attrCollectionId}:POST",
        map: {
          attrCollectionId: this.entityName
        },
        mode: "add",
        initData: attribute
      };
      this.status = this.statusValues.add;
      this.expanded = [];
    },
    async edit_click(attribute) {
      this.formConfig = {
        url: this.$store.state.selected_resource,
        path:
          "_abac/attr-collection/{attrCollectionId}/{attrCollectionItem}:PUT",
        map: {
          attrCollectionId: this.entityName,
          attrCollectionItem: attribute.name
        },
        mode: "edit",
        initData: attribute
      };
      this.status = this.statusValues.edit;
      this.expanded = [];
    },
    async cancel_click() {
      this.status = this.statusValues.browse;
    },
    async save_click() {
      this.status = this.statusValues.browse;
      await this.getAttributes();
    },
    async delete_click(item) {
      let result = confirm(this.$t("delete_modal") + "\n\n" + item.name);
      if (!result) return;
      await callAPI({
        url:
          this.$store.state.selected_resource +
          "/_abac/attr-collection/" +
          this.entityName +
          "/" +
          item.name,
        method: "DELETE"
      });
      await this.getAttributes();
    }
  }
};
</script>
