<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <GsCollectionEntities v-model="entityName" />
      </v-col>
      <v-col>
        <GsCollectionAttributes :entityName="entityName" />
        <GsCollectionTags :entityName="entityName" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GsCollectionAttributes from "@/components/GsCollectionAttributes";
import GsCollectionTags from "@/components/GsCollectionTags";
import GsCollectionEntities from "@/components/GsCollectionEntities";

export default {
  name: "CollectionManager",
  components: {
    GsCollectionAttributes,
    GsCollectionTags,
    GsCollectionEntities
  },
  props: {},
  data() {
    return {
      entityName: ""
    };
  },
  created: async function() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
